<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    window.addEventListener('beforeunload', () => {
      // 不要忘了上面localstorage存数据的特性,用JSON.stringify
      sessionStorage.setItem('vuex', JSON.stringify(this.$store.state))
    })
    if (sessionStorage.getItem('vuex')) {
      // this.$store.state = JSON.parse(sessionStorage.getItem('vuex'))
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('vuex'))))
      //	因为感觉在storage看的到vuex不舒服，所以就加上了这步
      sessionStorage.removeItem('vuex')
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /*text-align: center;*/
  /*color: #2c3e50;*/
}
</style>
