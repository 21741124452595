import Vue from 'vue'
import Vuex from 'vuex'
// import {store} from "core-js/internals/reflect-metadata";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    home_page:{},
    //选择的软件版本下标
    software_type:0,

    // 后端返回的产品信息
    software:[],

    // 企业认证状态 默认3为已认证
    auth_status:3,

    //当前订单id
    order_id:'',

    //支付进度
    pay_state:3,

    //订单备注，路由嵌套使用
    pay_remarks:'',

    // 下载页面文章id，用来看教程详情
    article_id:null
  },
  getters: {
  },
  mutations: {
    sethome_page(state,data){
      state.home_page = data
    },
    // 选择软件版本 0文件加密、1上网行为审计
    soft_type(state,type){
      state.software_type = type
    },
    // 获取产品信息
    software(state,data){
      state.software = data
    },
    // 修改认证状态
    set_auth_status(state,data){
      state.auth_status = data
    },
    // 设置当前订单id
    set_order_id(state,data){
      state.order_id = data
    },
    // 修改支付状态
    edit_pay_status(state,data){
      state.pay_state = data
    },
    // 设置订单备注
    set_pay_remarks(state,data){
      state.pay_remarks = data
    },
    // 设置下载页面文章id
    set_article_id(state,data){
      state.article_id = data
    },
  },
  actions: {
    // 获取产品信息
    setSoftware(store,data){
      store.commit('software',data)
    },
    // 修改认证状态
    async_set_auth_status(state,data){
      state.commit('set_auth_status',data)
    },
    // 设置当前订单id
    async_set_order_id(state,data){
      state.commit('set_order_id',data)
    },
    // 修改支付状态
    async_edit_pay_status(state,data){
      state.commit('edit_pay_status',data)
    },
    // 设置订单备注
    async_set_pay_remarks(state,data){
      state.commit('set_pay_remarks',data)
    },
  },
  modules: {

  }
})
