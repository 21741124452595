import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    meta: {isLogin: true, title: '域智盾用户试用'}, // 需要守卫
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {isLogin: false, title: '域智盾用户登录'}, // 无需守卫
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {isLogin: false, title: '域智盾用户注册'},
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/re_password",
    name: "re_password",
    meta: {isLogin: false, title: '域智盾重置密码'},
    component: () => import("../views/re_password.vue"),
  },

  // 认证
  {
    path: "/auth",
    meta: {isLogin: true, title: '域智盾企业认证'},
    component: () => import("../views/authentication/index.vue"),
    children: [
      {
        path: "",
        name: "auth_start",
        meta: {isLogin: true, title: '域智盾企业认证'},
        component: () => import("../views/authentication/start.vue"),
      },
      {
        path: "resetinfo",
        name: "auth_resetinfo",
        meta: {isLogin: true, title: '域智盾重新填写企业认证信息'},
        component: () => import("../views/authentication/resetinfo.vue"),
      },
      {
        path: "step",
        name: "auth_step",
        meta: {isLogin: true},
        component: () => import("../views/authentication/step/index"),
        children: [
          {
            path: "info",
            name: "auth_info",
            meta: {isLogin: true, title: '域智盾填写企业认证信息'},
            component: () => import("../views/authentication/step/info.vue"),
          },
          {
            path: "wait",
            name: "auth_wait",
            meta: {isLogin: true, title: '域智盾等待企业认证审核'},
            component: () => import("../views/authentication/step/wait.vue"),
          },
          {
            path: "fail",
            name: "auth_fail",
            meta: {isLogin: true, title: '域智盾企业认证失败'},
            component: () => import("../views/authentication/step/fail.vue"),
          },
          {
            path: "success",
            name: "auth_success",
            meta: {isLogin: true, title: '域智盾企业认证成功'},
            component: () => import("../views/authentication/step/success.vue"),
          },
        ],
      },
    ],
  },
  //选择商品购买
  {
    path: "/buy",
    meta: {isLogin: true, title: '域智盾产品购买'},
    component: () => import("../views/buy/index"),
    children: [
      {
        path: "",
        name: "select_type",
        meta: {isLogin: true, title: '域智盾产品购买'},
        component: () => import("../views/buy/select_type"),
      },
      {
        path: "confirm",
        name: "confirm_type",
        meta: {isLogin: true, title: '域智盾确认订单'},
        component: () => import("../views/buy/confirm"),
      },
    ],
  },
  //确认下单，获取订单信息，转账
  {
    path: "/transfer",
    meta: {isLogin: true},
    component: () => import("../views/buy/transfer/index"),
    children: [
      {
        path: "",
        name: "wait_transfer",
        meta: {isLogin: true, title: '域智盾等待转账'},
        component: () => import("../views/buy/transfer/wait"),
      },
      {
        path: "collection",
        name: "collection",
        meta: {isLogin: true, title: '域智盾等待认款'},
        component: () => import("../views/buy/transfer/collection"),
      },
      {
        path: "success",
        name: "transfer_success",
        meta: {isLogin: true, title: '域智盾产品购买成功'},
        component: () => import("../views/buy/transfer/success"),
      },
    ],
  },
  // 测试用
  // 上传组件
  {
    path: "/upload_img",
    name: "upload_img",
    meta: {isLogin: false},
    component: () => import("../views/authentication/upload_img.vue"),
  },
  {
    path: "/",
    meta: {isLogin: true},
    component: () => import("../views/router_view_sidebar.vue"),
    children: [
      // 主页
      {
        path: "",
        name: "page_index",
        meta: {isLogin: true, title: '域智盾用户主页'},
        component: () => import("@/views/user_index"),
      },
      // 用户信息
      {
        path: "userinfo",
        name: "page_userinfo",
        meta: {isLogin: true, title: '域智盾用户信息'},
        component: resolve => require(["@/views/userinfo/index.vue"], resolve),
      },
      // 资质认证
      {
        path: "authentication",
        name: "page_authentication",
        meta: {isLogin: true},
        component: () => import("@/views/index_auth/index.vue"),
        children: [
          {
            path: "",
            name: "auth_start",
            meta: {isLogin: true, title: '域智盾资质认证'},
            component: () => import("@/views/index_auth/start.vue"),
          },
          {
            path: "resetinfo",
            name: "auth_resetinfo",
            meta: {isLogin: true, title: '域智盾资质认证'},
            component: () => import("@/views/index_auth/resetinfo.vue"),
          },
          {
            path: "step",
            name: "auth_step",
            meta: {isLogin: true, title: '域智盾资质认证'},
            component: () => import("@/views/index_auth/step/index"),
            children: [
              {
                path: "info",
                name: "auth_info",
                meta: {isLogin: true, title: '域智盾资质认证'},
                component: () => import("@/views/index_auth/step/info.vue"),
              },
              {
                path: "wait",
                name: "auth_wait",
                meta: {isLogin: true, title: '域智盾资质认证'},
                component: () => import("@/views/index_auth/step/wait.vue"),
              },
              {
                path: "fail",
                name: "auth_fail",
                meta: {isLogin: true, title: '域智盾资质认证'},
                component: () => import("@/views/index_auth/step/fail.vue"),
              },
              {
                path: "success",
                name: "auth_success",
                meta: {isLogin: true, title: '域智盾资质认证'},
                component: () => import("@/views/index_auth/step/success.vue"),
              },
            ],
          }
        ]
      },

      // 我的订单
      {
        path: "myOrder",
        name: "page_myOrder",
        meta: {isLogin: true, title: '域智盾我的订单'},
        component: () => import("@/views/myorder"),
      },
      // 发票管理
      // {
      //   path: 'invoice',
      //   name: 'page_invoice',
      // meta: { isLogin: true },
      //   component: () => import('../views/allorder/pagefive.vue'),
      // },
      // // 我的试用
      // {
      //   path: 'myTrial',
      //   name: 'page_myTrial',
      //   meta: { isLogin: true },
      //   component: () => import('../views/allorder/pagesix.vue'),
      // },
      // // 帮助与文档
      // {
      //   path: 'help',
      //   name: 'page_help',
      //   meta: { isLogin: true },
      //   component: () => import('../views/allorder/pagesix.vue'),
      // }
    ],
  },
  {
    path: "/article",
    meta: {isLogin: true, title: '下载软件'},
    component: () => import("@/views/article/index"),
    children: [
      {
        path: "",
        name: "article_list",
        meta: {isLogin: true},
        component: () => import("@/views/article/list"),
      },
      {
        path: "detail",
        name: "article_detail",
        meta: {isLogin: true},
        component: () => import("@/views/article/detail"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '域智盾控制台' //修改网页的title
  // console.log(to.meta);
  // console.log(to.fullPath);

  // 判断即将进入的路由对象是否需要守卫
  if (to.meta.isLogin) {
    // 需要守卫
    // 判断当前用户的登陆状态  登陆 直接查看next()  未登录  拦截到登陆页
    if (getCookie("token")) {
      // 登陆凭证token
      next();
    } else {
      // 未登录
      next({
        path: "/login",
        query: {
          topath: to.fullPath,
        },
      });
    }
  } else {
    next(); // 不需要守卫，接着执行
  }
});

// //全局后置守卫：初始化时执行、每次路由切换后执行
// router.afterEach((to, from) => {
//   console.log('afterEach', to, from)
//   document.title = to.meta.title || '域智盾控制台' //修改网页的title
// })

function getCookie(cookieName) {
  const value = document.cookie.match(
    "(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
  );
  return value ? value.pop() : "";
}

export default router;
